import React, { useEffect, useState } from 'react';
import slugify from 'slugify';

import Filter from '../Filter';
import Layout from '../Layout';
import MainWorkGrid from '../MainWorkGrid';
import PageTitle from '../PageTitle';
import Spacer from '../Spacer';

const filterProjects = (data, category) => {
  if (category === 'all') return data;
  return data.filter(e =>
    category
      ? e.metadata.categories.filter(cat => {
          return slugify(cat).toLowerCase() === category;
        }).length
      : true
  );
};

export default props => {
  const {
    pageContext: { projectsMeta, searchIndex },
  } = props;

  const {
    location: { pathname },
  } = props;

  const [currCat, setCurrCat] = useState('all');

  useEffect(() => {
    const currentLocation = slugify(
      pathname.substr(pathname.lastIndexOf('/') + 1)
    );
    setCurrCat(currentLocation);
  }, [props.location.pathname]);

  return (
    <>
      <Layout background={false} searchIndex={searchIndex}>
        <PageTitle>Our Work</PageTitle>
        <Filter
          categories={[
            'Shows',
            'Spaces',
            'Moving Images',
            'Digital',
            'Installations',
            'Identity',
          ]}
          active={currCat}
        />
        <Spacer space="3.3rem" />
        <MainWorkGrid projects={filterProjects(projectsMeta, currCat)} />
      </Layout>
    </>
  );
};
