import { Link } from 'gatsby';
import React from 'react';
import slugify from 'slugify';
import styled from 'styled-components';

import InternalLink from './InternalLink';

const SmallInternalLink = styled(InternalLink)`
  font-size: 1.2rem;
  padding: 1rem;
  border: none;
  color: ${props => (props.active ? 'black' : 'grey')};
  &:hover {
    color: black;
    border-color: black;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  @media all and (max-width: 700px) {
    flex-direction: column;
    overflow: hidden;
    height: ${props => (props.open ? '25rem' : '1.5rem')};
    justify-content: flex-start;
    transition: height 0.5s ease-in-out;
  }
`;

export default class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.handleOpenClick = this.handleOpenClick.bind(this);
  }

  handleOpenClick(e) {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  }

  render() {
    const { categories, active } = this.props;
    if (!categories) return null;
    return (
      <>
        <Wrapper open={this.state.open}>
          {typeof document !== 'undefined' &&
            document.documentElement.clientWidth <= 700 && (
              <InternalLink onClick={this.handleOpenClick}>
                Filter {this.state.open ? '-' : '+'}
              </InternalLink>
            )}
          <Link to="/work/all">
            <SmallInternalLink small active={active === 'all'}>
              All Projects
            </SmallInternalLink>
          </Link>
          {categories.map((cat, i) => (
            <Link to={`/work/${slugify(cat).toLowerCase()}`} key={cat + i}>
              <SmallInternalLink
                small
                active={slugify(cat).toLowerCase() === active}
              >
                {cat}
              </SmallInternalLink>
            </Link>
          ))}
        </Wrapper>
      </>
    );
  }
}
